<template>
	<div class="edit-profile-image">
		<Spinner v-if="loading" />
		<ProfileImage
			:user="user"
			:view-only="false"
			class="cursor-pointer"
			color="none"
			:smaller="smaller"
			@click-img="$modal.show('editProfileImageModal')"
		>
			<template #edit>
				<!--show empty user avatar-->
				<div
					v-if="!user.images"
					:class="[[smaller ? 'w-16 h-16' : 'w-32 h-32']]"
					:style="{
						backgroundImage: useColouredPlaceholder
							? `url('${$img('/pwa-core-nuxt3/img/coloured-no-user-image.png')}')`
							: `url('${$img('/pwa-core-nuxt3/img/unknown_user.png')}')`
					}"
					class="profile-image cursor-pointer rounded-full mx-auto bg-cover"
					@click="clickUpload"
				/>
				<div
					v-if="!currentlyUploading && !showDedicatedSaveButton"
					class="text-color-text text-sm font-bold bg-color-white shadow-xl rounded-full flex space-x-2 items-center self-center py-2 px-4 -mt-4"
					@click="user.images ? $modal.show('editProfileImageModal') : clickUpload()"
				>
					<HokIcon name="icon:camera" color="text" :size="5" pointer />
					<p v-if="user.images" class="mb-0 leading-none">Bearbeiten</p>
					<p v-else class="mb-0 leading-none">Hinzufügen</p>
				</div>
				<HokButton
					v-if="showDedicatedSaveButton"
					:disabled="currentlyUploading"
					class="my-4"
					fullwidth="mobile"
					color="main"
					@click="clickUpload"
					>Foto hochladen</HokButton
				>
				<!--while uploading show progress-->
				<p v-if="currentlyUploading" class="self-center">{{ uploadProgressPercent }}%</p>
				<form ref="uploadform" class="mt-4 hidden">
					<input
						id="file"
						ref="file"
						type="file"
						accept="image/*"
						class="hidden"
						@change="selectFileForUpload"
					/>
				</form>
			</template>
		</ProfileImage>
		<HokModal
			width="40%"
			height="80%"
			class="cropper-modal cursor-default"
			name="cropperModal"
			@closed="cancel"
		>
			<client-only>
				<ImageCropper
					:is-company-app="companyMode"
					:image-data="imageData"
					@cancel="cancel(true)"
					@upload="upload"
				/>
			</client-only>
		</HokModal>
		<HokModal name="editProfileImageModal" class="cursor-default">
			<h2 class="text-center">Profilbild bearbeiten</h2>
			<p class="text-center">
				{{ companyMode ? 'Sie können entweder Ihr ' : 'Du kannst entweder dein ' }}aktuelles
				Profilbild löschen oder ein neues hochladen.
			</p>
			<HokBgImg
				v-if="user.images"
				:source="user.images.small"
				class="rounded-full w-32 h-32 mx-auto mb-8"
			/>
			<div class="flex flex-col mx-auto">
				<HokButton
					outline
					color="main"
					fullwidth="mobile"
					class="mb-4"
					@click="callDeleteProfileImage"
					>Löschen</HokButton
				>
				<HokButton fullwidth="mobile" color="main" @click="updateProfileImage"
					>Neues Bild hochladen</HokButton
				>
			</div>
		</HokModal>
	</div>
</template>

<script lang="ts">
import ProfileImage from '@hokify/shared-components-nuxt3/lib/components/ProfileImage.vue';
import type { IAPIUser } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import ImageCropper from '@hokify/shared-components-nuxt3/lib/components/ImageCropper.vue';

function isForm(obj: any): obj is { reset(): void } {
	return obj && typeof obj.reset === 'function';
}

function isHTMLInputElement(file: any): file is HTMLInputElement {
	return file && file instanceof HTMLInputElement;
}

export default defineComponent({
	components: {
		ProfileImage,
		ImageCropper
	},
	emits: ['upload-finished'],
	data() {
		const imageData = null as string | ArrayBuffer | null;
		const uploadProgressPercent = 0;
		const currentlyUploading = false;
		const loading = false;

		return {
			uploadProgressPercent,
			currentlyUploading,
			imageData,
			loading,
			EventBus
		};
	},
	beforeUnmount() {
		this.EventBus.$off('cancel');
		this.EventBus.$off('upload');
	},
	methods: {
		async callDeleteProfileImage() {
			try {
				await this.deleteProfileImage();
				this.$modal.hide('editProfileImageModal');
				this.$snack.success({
					text: `${this.companyMode ? 'Ihr ' : 'Dein '}Profilbild wurde gelöscht.`
				});
			} catch (err: any) {
				return this.$nuxt.$errorHandler(
					err,
					`Es ist ein Fehler beim Löschen ${
						this.companyMode ? 'Ihres ' : 'deines '
					} Profilbildes aufgetreten. Bitte ${
						this.companyMode ? 'versuchen Sie ' : 'versuche '
					}es später noch einmal.`
				);
			}
		},
		updateProfileImage() {
			this.$modal.hide('editProfileImageModal');
			this.clickUpload();
		},
		cancel(close: boolean) {
			// reset form
			if (isForm(this.$refs.uploadform)) {
				this.$refs.uploadform.reset();
			}

			// close modal
			if (close) {
				if (this.$isMobile.any) {
					this.$page.goBack();
				} else {
					this.$modal.hide('cropperModal');
				}
			}
			this.imageData = null;
			this.EventBus.$off('cancel');
		},
		selectFileForUpload() {
			this.loading = true;
			const file: false | File | null =
				isHTMLInputElement(this.$refs.file) && this.$refs.file.files && this.$refs.file.files[0];
			const reader = new FileReader();

			const onReaderLoad = () => {
				this.imageData = reader.result;
				if (this.$isMobile.any) {
					this.loadImageCropperPage();
				} else {
					this.$nextTick(async () => {
						await this.$modal.show('cropperModal');
						this.loading = false;
					});
				}
			};

			if (typeof reader.addEventListener === 'function') {
				reader.addEventListener('load', onReaderLoad, false);
			} else {
				reader.onload = onReaderLoad;
			}

			/* Ensure the file is not empty & an image file. */
			if (file) {
				if (file.type && !file.type.includes('image/')) {
					this.$snack.danger({ text: 'Bitte eine Bilddatei auswählen.' });
					return;
				}

				reader.readAsDataURL(file);
			}
		},
		clickUpload() {
			if (isHTMLInputElement(this.$refs.file)) {
				if (this.$route?.name === 'pwa-profile-tab') {
					this.$trackUserEvent?.('user_settings_pic_select', {
						answerType: 'file-profilepic'
					});
				} else if (this.$route?.name === 'apply-jobNr') {
					this.$trackUserEvent?.('interview_question_pic_select', {});
				}

				this.$refs.file.click();
			} else {
				console.error('upload not ready');
			}
		},
		async upload(imageData: string | Blob) {
			if (this.currentlyUploading) {
				return;
			}

			this.currentlyUploading = true;

			const formData = new FormData();

			formData.append('media', imageData);

			try {
				await this.updatePicture({
					postData: formData,
					onUploadProgress: evt => {
						if (evt.total) {
							this.uploadProgressPercent = Math.round((evt.loaded / evt.total) * 100);
						}
					}
				});

				this.currentlyUploading = false;

				if (!this.companyMode) {
					if (this.$route?.name === 'pwa-webupload-docType') {
						this.$trackUserEvent?.('user_settings_pic_finish', {
							answerType: 'mail-pic-upload'
						});
					} else if (this.$route?.name === 'apply-jobNr') {
						this.$trackUserEvent?.('interview_question_pic_finish', {});
					} else {
						this.$trackUserEvent?.('user_settings_pic_finish', {
							answerType: 'file-profilepic'
						});
					}
				}

				this.$emit('upload-finished');

				this.$snack.success({
					text: `${
						this.companyMode ? 'Ihr ' : 'Dein '
					}Profilbild wurde erfolgreich hochgeladen und gespeichert.`
				});
			} catch (err: any) {
				console.error('upload error', err);
				this.currentlyUploading = false;

				const code = this.$nuxt.$getErrorCode(err);
				if (code === 'FILE_TOO_LARGE') {
					this.$snack.danger({
						text:
							err?.data?.currentBytes && err?.data?.maxBytes
								? `Diese Datei ist zu groß (${(err.data.currentBytes / 1000000).toFixed(
										2
									)}MB): maximale Größe ${(err.data.maxBytes / 1000000).toFixed(2)}MB`
								: 'Diese Datei ist zu groß: maximale Größe 10MB',
						button: 'anderes Bild hochladen',
						action: () => {
							this.clickUpload();
						}
					});
				} else if (code) {
					this.$snack.danger({
						text: 'Fehler beim Upload! Erlaubte Dateiformate: png, jpg, jpeg',
						button: 'anderes Bild hochladen',
						action: () => {
							this.clickUpload();
						}
					});
				} else {
					this.$nuxt.$errorHandler(err);
				}
			}
		},
		async loadImageCropperPage() {
			try {
				await this.$page.push(
					ImageCropper,
					{
						imageData: this.imageData,
						showTitle: false
					},
					{
						modalHeading: 'Profilbild zuschneiden',
						name: 'ImageCropper',
						mode: 'modal'
					}
				);
				this.EventBus.$on('cancel', () => this.cancel(true));
				this.EventBus.$on('upload', image => {
					this.upload(image);
					this.EventBus.$off('upload');
				});
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
			this.loading = false;
		},
		deleteProfileImage() {
			return this.$nuxt?.$userProfileGeneralStore?.deleteProfileImage();
		},
		updatePicture(payload) {
			return this.$nuxt?.$userProfileGeneralStore?.updatePicture(payload);
		}
	},
	props: {
		showDedicatedSaveButton: { type: Boolean, default: false },
		smaller: { type: Boolean, default: false },
		useColouredPlaceholder: { type: Boolean, default: false },
		user: { type: Object as PropType<IAPIUser>, required: true },
		companyMode: { type: Boolean, default: false }
	}
});
</script>
<style scoped lang="scss">
.edit-profile-image ::v-deep(.v--modal-box) {
	max-height: 80%;
	display: flex;
	flex-direction: column;
}
</style>
